// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-21z3U{position:relative;background-image:url(\"/img/signup/preload.cover.svg\");background-size:100% 100%;background-repeat:no-repeat;cursor:pointer;transition:transform 0.1s ease-out, box-shadow 0.1s ease-out;box-shadow:0 1px 1px rgba(0,0,0,0.05),0 2px 6px rgba(0,0,0,0.1)}@media (min-width: 600px){.container-21z3U:hover{transform:translateY(-2px);box-shadow:0 1px 3px rgba(0,0,0,0.05),0 4px 8px rgba(0,0,0,0.2)}}.overlay-z-MjY{display:flex;justify-content:center;z-index:2;background:rgba(255,98,85,0.9);position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;transition:opacity 0.1s ease-in}.overlaySelected-12zq9{opacity:1}.checkIcon-1HkGm{color:#fff;width:70px;transform:scale(1.2);transition:transform 0.2s ease-in;margin:0 auto}.checkIconSelected-1Re2I{transform:scale(1)}.image-EuO5O{z-index:1}.checkbox-3mS0U{position:absolute;opacity:0;left:0;top:0}\n", ""]);
// Exports
exports.locals = {
	"container": "container-21z3U",
	"overlay": "overlay-z-MjY",
	"overlaySelected": "overlaySelected-12zq9",
	"checkIcon": "checkIcon-1HkGm",
	"checkIconSelected": "checkIconSelected-1Re2I",
	"image": "image-EuO5O",
	"checkbox": "checkbox-3mS0U"
};
module.exports = exports;
