import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DialogFooter } from '@blendle/lego';
import Link from 'components/Link';
import { translate } from 'instances/i18n';
import CSS from './PublicationsFooter.scss';

function PublicationsFooter(props) {
  const buttonStyles = classNames('btn', [CSS.nextButton]);
  const { successUrl, onNext } = props;

  return (
    <DialogFooter className={CSS.footer}>
      <Link className={buttonStyles} onClick={onNext} href={successUrl} data-testid="nextButton">
        {translate('preferences.next')}
      </Link>
    </DialogFooter>
  );
}

PublicationsFooter.propTypes = {
  onNext: PropTypes.func.isRequired,
  successUrl: PropTypes.string.isRequired,
};

export default PublicationsFooter;
