import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router/lib/withRouter';
import AltContainer from 'alt-container';
import {
  STATUS_ERROR,
  HOME_ROUTE,
  REDIRECT_TO_URL,
  SIGNUP_TYPE_PREMIUM_ONBOARDING,
  SIGNUP_TYPE_DEEPLINK,
  SIGNUP_TYPE_AFFILIATE,
} from 'app-constants';
import { get } from 'lodash';
import LoginStore from 'stores/LoginStore';
import AuthStore from 'stores/AuthStore';
import AuthActions from 'actions/AuthActions';
import AffiliatesStore from 'stores/AffiliatesStore';
import SignUpStore from 'stores/SignUpStore';
import { replaceLastPath } from 'helpers/url';
import { getSignUpAffiliateMetaData } from 'helpers/affiliates';
import Login from 'modules/premiumSignup/components/Login';
import LoginActions from 'actions/LoginActions';
import ApplicationActions from 'actions/ApplicationActions';
import ApplicationState from 'instances/application_state';

class LoginContainer extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this._onSendLoginEmail = this._onSendLoginEmail.bind(this);
    this._onStoreChange = this._onStoreChange.bind(this);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    LoginStore.listen(this._onStoreChange);
  }

  componentWillUnmount() {
    LoginStore.unlisten(this._onStoreChange);
    clearTimeout(this._dispatchTimeout);
  }

  _clearError = () => {
    this.setState({ error: null });
  };

  _onStoreChange = storeState => {
    if (storeState.login.status === STATUS_ERROR) {
      this.setState({ error: storeState.login.error.message });
    }
  };

  _onSubmit = formState => {
    const { email, password } = formState;
    const credentials = {
      login: email,
      password,
    };

    this.setState({ formState });
    this._clearError();

    LoginActions.loginWithCredentials(credentials);
  };

  _onSendLoginEmail = login => {
    const { affiliate, meta } = AffiliatesStore.getState();
    let redirectUrl = HOME_ROUTE;

    if (affiliate && affiliate.name === 'vodafone' && meta && meta.vodafone_full_url) {
      const params = meta.vodafone_full_url.split('?')[1];
      redirectUrl = `/getpremium/actie/vodafone/activate?${params}`;
    }

    LoginActions.sendLoginEmail(login, null, redirectUrl, {
      location_in_layout: 'login-dialog',
    });
  };

  _onFacebookSignup = () => {
    const { router } = this.props;

    router.push(replaceLastPath(window.location.pathname, 'channels'));
  };

  _onFacebookLogin = () => {
    if (ApplicationState.get('requireAuthUrl')) {
      ApplicationActions.set(REDIRECT_TO_URL, ApplicationState.get('requireAuthUrl'));
    }

    this.props.router.push('/');
  };

  _getBaseSignUpContext() {
    const { itemId } = this.props.params;
    const signupStoreState = SignUpStore.getState();
    const metaData = getSignUpAffiliateMetaData(AffiliatesStore.getState());

    return {
      referrer: 'https://blendle.com/getpremium',
      ...signupStoreState.context,
      ...metaData,
      ...(itemId ? { signup_deeplink_item: itemId } : null),
    };
  }

  _getFacebookSignUpType() {
    const { itemId } = this.props.params;
    const { affiliate } = AffiliatesStore.getState();

    if (itemId) {
      return SIGNUP_TYPE_DEEPLINK;
    }

    if (affiliate) {
      return SIGNUP_TYPE_AFFILIATE;
    }

    return SIGNUP_TYPE_PREMIUM_ONBOARDING;
  }

  _renderSignUp = ({ authState, loginState }) => {
    return (
      <Login
        error={this.state.error}
        loginState={loginState.login}
        emailLoginState={loginState.loginEmail}
        clearError={this._clearError}
        onSubmit={this._onSubmit}
        onFacebookSignUp={this._onFacebookSignup}
        onFacebookLogin={this._onFacebookLogin}
        facebookSignUpType={this._getFacebookSignUpType()}
        facebookSignUpContext={this._getBaseSignUpContext()}
        onSendLoginEmail={this._onSendLoginEmail}
        disabled={this.props.disabled}
        userFormValue={authState.userInput}
        onUserFormInput={AuthActions.userInputChange}
      />
    );
  };

  render() {
    return (
      <AltContainer
        stores={{
          loginState: LoginStore,
          authState: AuthStore,
        }}
        render={this._renderSignUp}
      />
    );
  }
}

export default withRouter(LoginContainer);
