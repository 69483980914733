// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header-3xiG-{text-align:center;display:flex;flex-direction:column;align-items:center;padding:80px 0}@media screen and (max-width: 1025px){.header-3xiG- br{display:none}}@media screen and (max-width: 600px){.header-3xiG-{padding:30px 0}}.sectionTitle-3t2CC{font-size:60px;line-height:1em;opacity:0;animation-delay:0.1s}@media screen and (max-width: 600px){.sectionTitle-3t2CC{font-size:2.57143em;line-height:1em}}.sub-3IGPM{display:block;padding:30px 0 40px;font-size:22px;line-height:1.5em;color:#948784;opacity:0;animation-delay:0.3s}@media screen and (max-width: 600px){.sub-3IGPM{font-size:1.28571em;line-height:1.2em;padding:30px 0 40px}}\n", ""]);
// Exports
exports.locals = {
	"header": "header-3xiG-",
	"sectionTitle": "sectionTitle-3t2CC",
	"sub": "sub-3IGPM"
};
module.exports = exports;
