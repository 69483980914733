// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".disclaimer-3VRhn{display:none}.disclaimer-3VRhn a{color:inherit}@media (min-width: 600px){.disclaimer-3VRhn{display:block}}\n", ""]);
// Exports
exports.locals = {
	"disclaimer": "disclaimer-3VRhn"
};
module.exports = exports;
