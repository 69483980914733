// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".resetPassword-3mIax .reset-token-form h4{font-size:24px;margin:10px 0 1em}.resetPassword-3mIax .reset-token-form [type='submit']{margin-top:10px;width:100%}.resetPassword-3mIax .lnk-reset-token{display:none !important}\n", ""]);
// Exports
exports.locals = {
	"resetPassword": "resetPassword-3mIax"
};
module.exports = exports;
