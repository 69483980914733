import React from 'react';
import { string } from 'prop-types';
import { DialogBody } from '@blendle/lego';
import DialogHeader from 'modules/premiumSignup/components/DialogHeader';
import DialogSubheader from 'modules/premiumSignup/components/DialogSubheader';

const ActivatePremium = ({ error }) => {
  const title = error ? 'Er is iets mis gegaan' : 'Blendle Premium';
  const bodyCopy = error || 'Bezig met activeren...';

  return (
    <DialogBody>
      <DialogHeader>{title}</DialogHeader>
      <DialogSubheader>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: bodyCopy }} />
      </DialogSubheader>
    </DialogBody>
  );
};

ActivatePremium.propTypes = {
  error: string,
};

ActivatePremium.defaultProps = {
  error: undefined,
};

export default ActivatePremium;
