import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CSS from './DialogHeader.scss';

function DialogHeader(props) {
  const { children, className } = props;
  const headerClasses = classNames(className, CSS.header);

  return <h1 className={headerClasses}>{children}</h1>;
}

DialogHeader.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DialogHeader;
