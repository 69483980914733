import React, { Component } from 'react';
import { STATUS_ERROR, STATUS_OK, HOME_ROUTE } from 'app-constants';
import SubscriptionOrderActions from 'actions/SubscriptionOrderActions';
import SubscriptionOrderStore from 'stores/SubscriptionOrderStore';
import AuthStore from 'stores/AuthStore';
import AffiliatesStore from 'stores/AffiliatesStore';
import { getVodafoneAffiliateMetaData } from 'helpers/affiliates';
import getSubscriptionOverviewPageUri from 'helpers/paymentPageUrl/getSubscriptionOverviewPageUri';
import ActivatePremium from '../components/ActivatePremium';
import { isAlreadyPremiumError } from '../helpers/isAlreadyPremiumError';

const activateVodafoneSubscription = () => {
  const { affiliate, meta } = AffiliatesStore.getState();
  const { user } = AuthStore.getState();
  const successUrl = `${HOME_ROUTE}/success`;

  if (!affiliate || !affiliate.subscriptionProduct) {
    throw new Error('Missing required data to start Vodafone subscription');
  }

  const subscriptionOptions = {
    success_url: successUrl,
    user_uuid: user.attributes.uuid,
    subscriptionProductId: affiliate.subscriptionProduct,
    ...getVodafoneAffiliateMetaData(meta),
  };

  return SubscriptionOrderActions.startAffiliateSubscription(subscriptionOptions, user.id);
};

class ActivateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    SubscriptionOrderStore.listen(this.onStoreChange);

    return activateVodafoneSubscription();
  }

  componentWillUnmount() {
    SubscriptionOrderStore.unlisten(this.onStoreChange);
  }

  onStoreChange = storeState => {
    const { affiliateStatus, redirect_url: redirectUrl, error } = storeState;
    const { affiliate } = AffiliatesStore.getState();

    if (affiliateStatus === STATUS_OK) {
      if (affiliate && affiliate.name === 'vodafone') {
        window.location = redirectUrl;
        return;
      }
    }

    if (affiliateStatus === STATUS_ERROR) {
      this.getErrorMessage(error);
    }
  };

  getErrorMessage = error => {
    if (isAlreadyPremiumError(error)) {
      return this.setState({
        errorMessage: `Je hebt al een Premium abonnement. Als je gebruik wilt maken van deze actie moet je eerst dit abonnement stopzetten. Dit kun je <a href="${getSubscriptionOverviewPageUri()}">hier</a> doen.`,
      });
    }

    const { status } = error;

    let errorMessage =
      'Stuur even een berichtje naar <a href="mailto:contact@blendle.com">contact@blendle.com</a>.';

    if (status === 422) {
      errorMessage = `${errorMessage} Zeg maar dat het om status code 422 gaat.`;
    }

    return this.setState({ errorMessage });
  };

  render() {
    const { errorMessage } = this.state;

    return <ActivatePremium error={errorMessage} />;
  }
}

export default ActivateContainer;
