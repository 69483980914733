// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3gI2v{min-height:calc(100vh - 30px);overflow:hidden;display:flex;justify-content:center;align-items:center}.blendleLogo-3U6_5{position:absolute;left:30px;top:0;height:auto}.vodafoneLogo-PZOU7{margin-bottom:2em;width:100px;height:100px;display:inline-block;background-image:url(\"/img/logos/vodafone.large.png\");background-size:100px 100px}@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx){.vodafoneLogo-PZOU7{background-image:url(\"/img/logos/vodafone.large@2x.png\")}}@media screen and (min-width: 600px){.vodafoneLogo-PZOU7{background-size:150px 150px;width:150px;height:150px}}.title-3_Oae{font-size:2.57143em;line-height:1em;margin-bottom:1em}@media screen and (min-width: 600px){.title-3_Oae{font-size:60px}}.message-JpJ7l{color:#948784}.message-JpJ7l button{cursor:pointer;text-decoration:underline;padding:0;color:#948784;font-size:inherit}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3gI2v",
	"blendleLogo": "blendleLogo-3U6_5",
	"vodafoneLogo": "vodafoneLogo-PZOU7",
	"title": "title-3_Oae",
	"message": "message-JpJ7l"
};
module.exports = exports;
