import React from 'react';
import { bool, node } from 'prop-types';
import Link from 'components/Link';
import withCapabilityIsLocked from 'higher-order-components/withCapabilityIsLocked';
import { FEATURE_CAPABILITY_CONTENT_PREFERENCE_SELECTOR } from 'app-constants';
import CSS from './LinkWhenCapabilityIsLocked.scss';

function LinkWhenCapabilityIsLocked({ capabilityIsLocked, isOnboarding, children }) {
  if (!isOnboarding && capabilityIsLocked) {
    return (
      <Link href="/features/content-preferences" className={CSS.upsellLink}>
        {children}
      </Link>
    );
  }

  return <span>{children}</span>;
}

LinkWhenCapabilityIsLocked.propTypes = {
  capabilityIsLocked: bool.isRequired,
  isOnboarding: bool,
  children: node.isRequired,
};

LinkWhenCapabilityIsLocked.defaultProps = {
  isOnboarding: false,
};

export const LinkWhenCapabilityIsLockedComponent = LinkWhenCapabilityIsLocked;

export default withCapabilityIsLocked(FEATURE_CAPABILITY_CONTENT_PREFERENCE_SELECTOR)(
  LinkWhenCapabilityIsLocked,
);
