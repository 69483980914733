// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-OAZPq{font-family:\"GT-Walsheim\",sans-serif;font-size:24px;margin-bottom:1em}@media screen and (max-width: 600px){.title-OAZPq{font-size:1.71429em}}.divider-HRkqA{display:block;text-align:center;padding:12px 0 11px;color:#7f7f7f}.divider-HRkqA:after,.divider-HRkqA:before{display:block;content:'';border-bottom:1px solid #eaeaea;width:42%;float:left;margin-top:8px}.divider-HRkqA:after{float:right}.intro-FRT5Y{margin:10px 0 20px}.intro-FRT5Y em{font-weight:bold}.emailError-AJwsj{text-decoration:none}.forgotPassword-1ajOx{text-align:left;width:100%;display:inline-block;color:#948784;font-size:.85714em;text-decoration:none}.forgotPassword-1ajOx:hover{color:#333}.signupForm-3NEfz label{text-align:left}.clearGlobalMargin-2ltKr{margin-bottom:0 !important}.footer-2dOIH{font-size:12px;color:#948784;text-align:center;margin:20px auto 0;justify-content:center}.hideOnDektop-2zTU7{display:block}@media (min-width: 600px){.hideOnDektop-2zTU7{display:none}}.errorMessage-2rYWK{color:#ff6255;font-weight:500}\n", ""]);
// Exports
exports.locals = {
	"title": "title-OAZPq",
	"divider": "divider-HRkqA",
	"intro": "intro-FRT5Y",
	"emailError": "emailError-AJwsj",
	"forgotPassword": "forgotPassword-1ajOx",
	"signupForm": "signupForm-3NEfz",
	"clearGlobalMargin": "clearGlobalMargin-2ltKr",
	"footer": "footer-2dOIH",
	"hideOnDektop": "hideOnDektop-2zTU7",
	"errorMessage": "errorMessage-2rYWK"
};
module.exports = exports;
