import React from 'react';
import { compose } from '@blendle/recompose';
import withRouter from 'react-router/lib/withRouter';
import withDialogs from '../higher-order-components/withDialogs';
import withAuthListener from '../higher-order-components/withAuthListener';

function PremiumAudioLandingContainer() {
  return <div>&nbsp;</div>;
}

const enhance = compose(withRouter, withDialogs, withAuthListener);

export default enhance(PremiumAudioLandingContainer);
