import { path, pipe, head, equals, anyPass, prop, ifElse, is, F } from 'ramda';

const alreadyActiveVodafoneSubscription = equals('AlreadyActiveVodafoneSubscription');
const alreadyActivePremiumSubscription = equals('AlreadyActivePremiumSubscription');

const isAlreadyPremiumSubscriptionError = anyPass([
  alreadyActiveVodafoneSubscription,
  alreadyActivePremiumSubscription,
]);

const isFirstErrorAlreadyPremiumError = pipe(
  head,
  prop('id'),
  isAlreadyPremiumSubscriptionError,
);

export const isAlreadyPremiumError = pipe(
  path(['response', 'data', '_errors']),
  ifElse(is(Array), isFirstErrorAlreadyPremiumError, F),
);
