import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SignUpFormContainer from '../../containers/SignUpFormContainer';
import SignupDisclaimer from '../SignupDisclaimer';
import CSS from './SignUp.scss';

const SignUp = ({ dialogTitle, introText }) => (
  <div>
    <h2 className={CSS.title}>{dialogTitle}</h2>
    <p className={CSS.intro}>{introText}</p>
    <SignUpFormContainer locationInLayout="signup_dialog" />
    <SignupDisclaimer className={classnames(CSS.footer, CSS.hideOnDektop)} />
  </div>
);

SignUp.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
};

export default SignUp;
