import React, { Component } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import altConnect from 'higher-order-components/altConnect';
import EntityActions from 'actions/EntityActions';
import EntityStore from 'stores/EntityStore';
import { filterProminent } from 'selectors/entity';
import EntityPreferences from '../components/EntityPreferences';

class EntitiesContainer extends Component {
  static propTypes = {
    entities: arrayOf(shape({ id: string.isRequired })).isRequired,
  };

  componentDidMount() {
    EntityActions.fetchAllEntities();
  }

  render() {
    const { entities } = this.props;

    return <EntityPreferences entities={entities} />;
  }
}

function mapStateToProps({ entityState }) {
  return {
    entities: filterProminent(entityState.entities),
  };
}

mapStateToProps.stores = { EntityStore };

export default altConnect(mapStateToProps)(EntitiesContainer);
