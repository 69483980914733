// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-28joV{font-family:'GT-Walsheim', sans-serif;margin:70px 0 30px;font-size:42px}@media (max-width: 600px){.title-28joV{margin:30px 0;font-size:32px}}.form-aGAcU{font-family:\"Proxima Nova\",Helvetica,Arial,sans-serif;width:100%;max-width:700px;font-size:24px;margin:0 auto}.input-3DgTo{margin-bottom:30px;text-align:left}.premium-signup-enter .copy-30xws,.premium-signup-enter .form-aGAcU,.premium-signup-appear .copy-30xws,.premium-signup-appear .form-aGAcU{opacity:0;transform:translateY(10px)}.premium-signup-enter.premium-signup-enter-active .copy-30xws,.premium-signup-enter.premium-signup-enter-active .form-aGAcU,.premium-signup-appear.premium-signup-appear-active .copy-30xws,.premium-signup-appear.premium-signup-appear-active .form-aGAcU{opacity:1;transform:translateY(0);transition:transform 0.15s ease-out 0.9s, opacity 0.15s ease-out 0.9s}.premium-signup-enter.premium-signup-enter-active .form-aGAcU,.premium-signup-appear.premium-signup-appear-active .form-aGAcU{transition-delay:1.05s, 1.05s}.premium-signup-leave .copy-30xws,.premium-signup-leave .form-aGAcU{opacity:1;transform:translateY(0)}.premium-signup-leave.premium-signup-leave-active .copy-30xws,.premium-signup-leave.premium-signup-leave-active .form-aGAcU{opacity:0;transform:translateY(-10px);transition:transform 0.15s ease-out 0.15s, opacity 0.15s ease-out 0.15s}.premium-signup-leave.premium-signup-leave-active .form-aGAcU{transition-delay:0.3s, 0.3s}\n", ""]);
// Exports
exports.locals = {
	"title": "title-28joV",
	"form": "form-aGAcU",
	"input": "input-3DgTo",
	"copy": "copy-30xws"
};
module.exports = exports;
