import Analytics from 'instances/analytics';
import googleAnalytics from 'instances/google_analytics';
import AuthStore from 'stores/AuthStore';
import {
  track,
  shouldTrackGAClickEvent,
} from 'helpers/premiumOnboardingEvents';
import facebook from 'helpers/trackingPixels/facebook';

function trackOnNext(user) {
  track(Analytics, 'Signup/Onboarding Completed');

  const { pathname } = window.location;
  if (shouldTrackGAClickEvent(pathname)) {
    googleAnalytics.trackEvent(pathname, 'button', 'ga door');
  }

  facebook.track('CompleteRegistration');
}

export default function finishSignup() {
  const { user } = AuthStore.getState();

  trackOnNext(user);

  return user.savePreferences({
    did_onboarding: true,
    did_premium_onboarding: true,
  });
}
