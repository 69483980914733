export function getCustomCopy(copyKey, affiliate) {
  if (!affiliate || !affiliate.copy) {
    return {};
  }

  return affiliate.copy[copyKey] || {};
}

export function getSignUpAffiliateMetaData({ affiliate } = {}) {
  if (affiliate && affiliate.name === 'vodafone') {
    return {
      signup_type: 'premium',
      affiliate: affiliate.name,
    };
  }

  return {};
}

export function getVodafoneAffiliateMetaData(meta) {
  if (meta && meta.vodafone_full_url) {
    return {
      ...meta,
      vodafone_full_url: decodeURIComponent(meta.vodafone_full_url),
      relation: 'vodafone_user_orders',
    };
  }

  return {};
}
