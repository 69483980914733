// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes slideIn-2KUTi{from{opacity:0;transform:translateY(100%)}to{opacity:1;transform:translateY(0)}}.nextButton-3AMut{width:220px;margin:0 auto}@media (max-width: 600px){.nextButton-3AMut{width:100%}}.footer-VTP_r{position:relative;z-index:10}.m-premiumsignup .footer-VTP_r{opacity:0;transform:translateY(100%);animation:slideIn-2KUTi 0.3s forwards ease-out 1.2s}\n", ""]);
// Exports
exports.locals = {
	"nextButton": "nextButton-3AMut",
	"footer": "footer-VTP_r",
	"slideIn": "slideIn-2KUTi"
};
module.exports = exports;
