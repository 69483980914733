// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".upsellLink-3qz5z{text-decoration:none}\n", ""]);
// Exports
exports.locals = {
	"upsellLink": "upsellLink-3qz5z"
};
module.exports = exports;
