// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialogFooter-1jpJ3 p{margin-bottom:0}.dialogFooter-1jpJ3 a{text-decoration:none}\n", ""]);
// Exports
exports.locals = {
	"dialogFooter": "dialogFooter-1jpJ3"
};
module.exports = exports;
