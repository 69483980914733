// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nextButton-DhPO7{width:220px;margin:0 auto}@media (max-width: 600px){.nextButton-DhPO7{width:100%}}\n", ""]);
// Exports
exports.locals = {
	"nextButton": "nextButton-DhPO7"
};
module.exports = exports;
