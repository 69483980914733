import React, { Component } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import AltContainer from 'alt-container';
import isDeeplinkSignup from 'helpers/deeplink';
import { STAFFPICKS } from 'app-constants';
import Analytics from 'instances/analytics';
import { track, internalLocation } from 'helpers/premiumOnboardingEvents';
import ChannelsStore from 'stores/ChannelsStore';
import ChannelActions from 'actions/ChannelActions';
import Channels from 'modules/premiumSignup/components/Channels';
import AuthStore from 'stores/AuthStore';
import GoogleAnalytics from 'instances/google_analytics';

const didSignupOnPremiumLandingPage = R.equals('premiumonboarding');

class ChannelsContainer extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
    isOnboarding: PropTypes.bool,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      clickPositions: {},
    };
  }

  componentDidMount() {
    ChannelActions.fetchChannels();
  }

  componentWillUnmount() {
    const channelState = ChannelsStore.getState();
    const { isOnboarding } = this.props;

    track(Analytics, 'Signup/Channels Selected', {
      channel_uids: channelState.selectedChannels,
    });

    if (isOnboarding) {
      GoogleAnalytics.trackOnboardingEvent('channels selected');
    }
  }

  onChangeChannel = (e, channel) => {
    const channelState = ChannelsStore.getState();
    const channelId = channel.id;
    const { user } = AuthStore.getState();
    const isFollowing = !channelState.selectedChannels.includes(channelId);
    const { clickPositions } = this.state;

    ChannelActions.followChannel(user.id, channelId, isFollowing, {
      internal_location: internalLocation(window.location.pathname),
    });

    const li = e.currentTarget.parentElement;
    const inkDiameter = Math.max(li.offsetWidth, li.offsetHeight);
    const rect = li.getBoundingClientRect();

    const pageX = e.pageX || rect.left + 20;
    const pageY = e.pageY || rect.top + 20;

    this.setState({
      clickPositions: {
        ...clickPositions,
        [channelId]: {
          x: pageX - rect.left - inkDiameter / 2,
          y: pageY - rect.top - inkDiameter / 2,
          inkDiameter,
        },
      },
    });
  };

  renderChannels = ({ channelState }) => {
    const channelsWithoutStaffpicks = channelState.channels.data.filter(
      channel => !Object.values(STAFFPICKS).includes(channel.id),
    );
    const { user } = AuthStore.getState();
    const { clickPositions } = this.state;
    const { isOnboarding, route } = this.props;
    const { module } = route;

    const userIsOnboarding = isOnboarding || !user.getPreference('did_premium_onboarding');

    return (
      <Channels
        channels={channelsWithoutStaffpicks}
        onSelectChannel={this.onChangeChannel}
        selectedChannels={channelState.selectedChannels}
        clickPositions={clickPositions}
        isOnboarding={userIsOnboarding}
        isDeeplinkSignUp={isDeeplinkSignup(user)}
        didSignupOnPremiumLandingPage={didSignupOnPremiumLandingPage(
          user.getPreference('signup_entry_point'),
        )}
        isPreferencesFlow={module === 'preferences'}
      />
    );
  };

  render() {
    return (
      <AltContainer
        stores={{
          channelState: ChannelsStore,
        }}
        render={this.renderChannels}
      />
    );
  }
}

export default ChannelsContainer;
