import PropTypes from 'prop-types';
import { compose, setPropTypes } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import AuthStore from 'stores/AuthStore';
import AffiliatesStore from 'stores/AffiliatesStore';
import withRouter from 'react-router/lib/withRouter';
import { getOnboardingSuccessUrl } from 'helpers/onboarding';
import { user } from 'helpers/user';
import PublicationsFooter from '../components/PublicationsFooter';
import finishSignup from '../helpers/finishSignup';

function mapStateToProps({ authState, affiliatesState }, { route }) {
  const routeBase = window.location.pathname.replace(`/${route.path}`, '');
  const currentUser = user(authState);

  return {
    successUrl: getOnboardingSuccessUrl(currentUser, routeBase, affiliatesState),
  };
}
mapStateToProps.stores = { AuthStore, AffiliatesStore };

const actions = { onNext: () => finishSignup() };

export default compose(
  withRouter,
  setPropTypes({
    route: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  }),
  altConnect(mapStateToProps, actions),
)(PublicationsFooter);
