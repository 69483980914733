import React from 'react';
import { DialogFooter } from '@blendle/lego';
import { replaceLastPath } from 'helpers/url';
import Link from 'components/Link';
import CSS from './SignupFooter.scss';

const SignupFooter = () => (
  <DialogFooter variant="transparent" className={CSS.dialogFooter}>
    <p>
      Heb je al een account?{' '}
      <Link href={replaceLastPath(window.location.pathname, 'login')}>Log hier in</Link>
    </p>
  </DialogFooter>
);

export default SignupFooter;
