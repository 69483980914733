import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router/lib/withRouter';
import AltContainer from 'alt-container';
import { get } from 'lodash';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';
import { removeTrailingSlash } from 'helpers/url';
import AuthStore from 'stores/AuthStore';
import BrowserEnvironment from 'instances/browser_environment';
import PremiumNavigation from '../components/PremiumNavigation';

class PremiumNavigationContainer extends PureComponent {
  static propTypes = {
    router: PropTypes.object,
  };

  renderPremiumNavigation = ({
    // disable eslint since it is a false-negative
    /* eslint-disable react/prop-types */
    authStore,
    moduleNavigationState,
    /* eslint-enable */
  }) => {
    const { user } = authStore;
    const isMobile = BrowserEnvironment.isMobile();
    const currentPath = removeTrailingSlash(
      moduleNavigationState.activeUrl || window.location.pathname,
    );
    const loginUrl = get(this.props.route, 'navigation.loginUrl') || `${currentPath}/login`;

    return (
      <div>
        <PremiumNavigation mobile={isMobile} authorizedUser={user} loginUrl={loginUrl} />
      </div>
    );
  };

  render() {
    return (
      <AltContainer
        stores={{
          authStore: AuthStore,
          moduleNavigationState: ModuleNavigationStore,
        }}
        render={this.renderPremiumNavigation}
      />
    );
  }
}

export default withRouter(PremiumNavigationContainer);
