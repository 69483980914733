import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CSS from './DialogSubheader.scss';

const DialogSubheader = ({ children, className }) => (
  <h3 className={classNames(CSS.subheader, className)}>{children}</h3>
);

DialogSubheader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DialogSubheader.defaultProps = {
  children: undefined,
  className: undefined,
};

export default DialogSubheader;
