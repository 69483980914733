import { string, oneOf, bool } from 'prop-types';
import altConnect from 'higher-order-components/altConnect';
import { compose, setPropTypes } from '@blendle/recompose';
import withRouter from 'react-router/lib/withRouter';
import { once } from 'lodash';
import {
  HOME_ROUTE,
  SIGNUP_TYPE_PREMIUM_ONBOARDING,
  SIGNUP_TYPE_AFFILIATE,
  SIGNUP_PLATFORM_FACEBOOK,
} from 'app-constants';
import SignUpStore from 'stores/SignUpStore';
import AuthStore from 'stores/AuthStore';
import AffiliatesStore from 'stores/AffiliatesStore';
import { getBaseUrl } from 'helpers/baseUrl';
import { getSuccesOrOnboardingUrl, getOnboardingRoute } from 'helpers/onboarding';
import { getSignUpAffiliateMetaData } from 'helpers/affiliates';
import SignUpForm from 'modules/premiumSignup/components/SignUpForm';
import SignUpActions from 'actions/SignUpActions';
import ApplicationState from 'instances/application_state';
import googleAnalytics from 'instances/google_analytics';
import Analytics from 'instances/analytics';
import { track, shouldTrackGAClickEvent } from 'helpers/premiumOnboardingEvents';

function trackGA(instance, pathname, action, label, value) {
  if (shouldTrackGAClickEvent(pathname)) {
    instance.trackEvent(pathname, action, label, value);
  }
}

const trackers = new Map([
  ['firstname', { event: 'Signup/First name', track: once(track) }],
  ['email', { event: 'Signup/Email', track: once(track) }],
  ['password', { event: 'Signup/Password', track: once(track) }],
]);

function onFacebookError({ message }, locationInLayout) {
  track(Analytics, 'Connect Facebook Error', {
    location_in_layout: locationInLayout,
    error: message,
  });
}

function onFacebookOpen(locationInLayout) {
  track(Analytics, 'Connect Facebook Start', {
    location_in_layout: locationInLayout,
  });
}

function onFacebookSignup(router) {
  SignUpActions.setSignupPlatform(SIGNUP_PLATFORM_FACEBOOK);

  router.push(getOnboardingRoute(window.location.pathname));

  trackGA(googleAnalytics, window.location.pathname, 'button', 'facebook signup');
}

function onFacebookLogin(user, router) {
  const returnUrl = ApplicationState.get('requireAuthUrl') || '/';

  router.push(getSuccesOrOnboardingUrl(user, returnUrl));

  trackGA(googleAnalytics, window.location.pathname, 'button', 'facebook login');
}

function onUserformInput({ name, value }, locationInLayout, userInput) {
  SignUpActions.signUpFormChange({
    ...userInput,
    [name]: value,
  });

  const tracker = trackers.get(name);

  if (value && tracker) {
    tracker.track(Analytics, tracker.event, {
      location_in_layout: locationInLayout,
    });
  }
}

export function getSuccessUrl() {
  const baseUrl = getBaseUrl();
  return `${baseUrl}${HOME_ROUTE}/success`;
}

function getAnalyticsSignUpType(affiliate, signupType) {
  if (signupType) return signupType;
  if (affiliate) return SIGNUP_TYPE_AFFILIATE;

  return SIGNUP_TYPE_PREMIUM_ONBOARDING;
}

function getBaseSignUpContext(storeContext, metaData) {
  return {
    referrer: 'https://blendle.com/getpremium',
    ...storeContext,
    ...metaData,
  };
}

function onSubmit(userFormValues, signUpAnalyticsPayload, analyticsSignUpType, signUpContext) {
  trackGA(googleAnalytics, window.location.pathname, 'button', 'start je gratis week');

  const { email, firstname: first_name, password } = userFormValues;
  const options = {
    verifyEmail: false,
    analyticsPayload: signUpAnalyticsPayload,
    signUpType: analyticsSignUpType,
  };
  const context = {
    first_name,
    password,
    ...signUpContext,
  };

  SignUpActions.signUp(email, context, options);
}

export const mapStateToProps = ({ signUpState, affiliatesState, authState }, ownProps) => {
  const { status: signupStatus, error, userInput: userFormValues, context } = signUpState;
  const { user } = authState;
  const { affiliate } = affiliatesState;
  const {
    locationInLayout,
    autoFocus,
    disabled,
    signUpType: signUpTypeFromProps,
    router,
    name,
  } = ownProps;
  const successUrl = getSuccessUrl();
  const analyticsSignUpType =
    signUpTypeFromProps || getAnalyticsSignUpType(affiliate, signUpTypeFromProps);
  const signUpMetaData = getSignUpAffiliateMetaData(affiliatesState);
  const signUpContext = {
    ...getBaseSignUpContext(context, signUpMetaData),
    success_url: successUrl,
  };

  const signUpAnalyticsPayload = {
    location_in_layout: locationInLayout,
  };

  return {
    error,
    signupStatus,
    disabled,
    userFormValues,
    locationInLayout,
    autoFocus,
    name,
    signUpType: analyticsSignUpType,
    facebookSignUpContext: signUpContext,
    onFacebookError: (fbError) => onFacebookError(fbError, locationInLayout),
    onFacebookOpen: () => onFacebookOpen(locationInLayout),
    onFacebookSignUp: () => onFacebookSignup(router),
    onUserFormInput: (e) => onUserformInput(e, locationInLayout, userFormValues),
    onFacebookLogin: () => onFacebookLogin(user, router),
    onSubmit: () => {
      onSubmit(userFormValues, signUpAnalyticsPayload, analyticsSignUpType, signUpContext);
    },
  };
};
mapStateToProps.stores = {
  SignUpStore,
  AffiliatesStore,
  AuthStore,
};

const enhance = compose(
  setPropTypes({
    itemId: string,
    signupType: string,
    disabled: bool,
    locationInLayout: oneOf(['inline_form', 'signup_dialog', 'recapture_section']).isRequired,
    autoFocus: bool,
  }),
  withRouter,
  altConnect(mapStateToProps),
);

export default enhance(SignUpForm);
