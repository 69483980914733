import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LandingCSS from '../style.scss';
import CSS from './style.scss';

class LandingHeader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;

    return (
      <section className={classNames(className, CSS.header)}>
        <div className={LandingCSS.sectionInner}>
          <div>
            <h1 className={classNames(CSS.sectionTitle, LandingCSS.slideUp)}>
              Het beste uit 161 kranten en
              <br /> tijdschriften op één plek
            </h1>
            <span className={classNames(CSS.sub, LandingCSS.slideUp)}>
              Elke dag de verhalen die bij jou passen.
              <br /> Zonder nepnieuws. Zonder filterbubbel.
            </span>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingHeader;
