import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { ChipRow } from '@blendle/lego';
import EntityContainer from '../../containers/TemporaryEntityContainer';

function EntityPreferences({ entities }) {
  return (
    <div>
      <h1>Entities</h1>
      <ChipRow>
        {entities.map(entity => <EntityContainer key={entity.id} entityId={entity.id} />)}
      </ChipRow>
    </div>
  );
}

EntityPreferences.propTypes = {
  entities: arrayOf(shape({ id: string.isRequired })).isRequired,
};

export default EntityPreferences;
