import React from 'react';
import { DialogFooter } from '@blendle/lego';
import { replaceLastPath } from 'helpers/url';
import Link from 'components/Link';
import CSS from '../SignupFooter/SignupFooter.scss';

const LoginFooter = () => (
  <DialogFooter variant="transparent" className={CSS.dialogFooter}>
    <p>
      Nog geen account?{' '}
      <Link href={replaceLastPath(window.location.pathname, 'signup')}>Maak nu eentje aan</Link>
    </p>
  </DialogFooter>
);

export default LoginFooter;
