import { HOME_ROUTE } from 'app-constants';
import { equals, path, compose, slice, split, curry } from 'ramda';
import { removeTrailingSlash } from 'helpers/url';
import { getSubscriptionPaymentPageUri } from 'helpers/paymentPageUrl/getSubscriptionPaymentPageUri';
import { getPreferredProductInterval } from './preferredProductInterval';
import { userEligibleForPremium } from './premiumEligibility';

const isPremiumOptOut = equals('premium_opt_out');
const affiliateName = path(['affiliate', 'name']);
const isVodafoneAffiliate = compose(equals('vodafone'), affiliateName);
const vodafoneFullUrl = path(['meta', 'vodafone_full_url']);
const urlParams = compose(slice(1, 2), split('?'));

function getVodafoneSuccessUrl(affiliatesState) {
  const url = vodafoneFullUrl(affiliatesState);

  return url ? `/getpremium/actie/vodafone/activate?${urlParams(url)}` : null;
}

function getPremiumOptOutOnboardingSuccessUrl(user) {
  if (user.hasActivePremiumSubscription()) {
    return HOME_ROUTE;
  }

  const deeplinkItemId = user.get('signup_deeplink_item');
  const successPath = deeplinkItemId
    ? `/item/${deeplinkItemId}?verified=true`
    : `${HOME_ROUTE}/success`;

  return getSubscriptionPaymentPageUri({
    successPath,
    productId: getPreferredProductInterval(),
  });
}

function shouldUserGetPremiumOptOutTrial(user, affiliatesState) {
  return (
    userEligibleForPremium(user) &&
    !user.hasActivePremiumSubscription() &&
    !affiliatesState.affiliate
  );
}

function getSuccessUrlForRemainingCases(user, routeBase) {
  if (!user.get('email_confirmed')) {
    return `${routeBase}/confirm`;
  }

  return user.hasActivePremiumSubscription() ? `${HOME_ROUTE}/success` : `${routeBase}/activate`;
}

export function getOnboardingSuccessUrl(user, routeBase, affiliatesState) {
  const couponCode = user.getPreference('signup_coupon_code');

  if (couponCode) {
    return getSubscriptionPaymentPageUri({
      paymentPath: '/start-with',
      couponCode,
    });
  }

  const signupType = user.get('signup_type');

  if (isPremiumOptOut(signupType)) {
    return getPremiumOptOutOnboardingSuccessUrl(user);
  }

  if (isVodafoneAffiliate(affiliatesState)) {
    const vodafoneSuccessUrl = getVodafoneSuccessUrl(affiliatesState);

    if (vodafoneSuccessUrl) return vodafoneSuccessUrl;
  }

  // To catch users that are not new users that are onboarding but other cases like:
  //  - button signups that log into Blendle
  //  - users that signed up in the past but never used their account
  //  - user that signup via edge case flows in webclient, amongst others:
  //    - facebook button on email login page,
  //    - facebook butto at the bottom of the item email-login page
  if (shouldUserGetPremiumOptOutTrial(user, affiliatesState)) {
    return getSubscriptionPaymentPageUri({
      paymentPath: '/try-now',
    });
  }

  return getSuccessUrlForRemainingCases(user, routeBase);
}

export function getSuccesOrOnboardingUrl(user, url) {
  return user.didOnboarding() ? url : '/getpremium/channels';
}

export const getRoute = curry((affiliateRoute, regularRoute, currentAffiliate) => {
  if (currentAffiliate && currentAffiliate.name) {
    return affiliateRoute.replace(':affiliateId', currentAffiliate.name);
  }

  return regularRoute;
});

export const stripSignupRoute = (pathname) => {
  const baseRoute = removeTrailingSlash(pathname);
  return baseRoute.replace(/\/signup|\/login/, '');
};

export const getOnboardingRoute = (pathname) => {
  const strippedRoute = stripSignupRoute(pathname);
  return `${strippedRoute}/channels`;
};
