import React from 'react';
import { shape, number } from 'prop-types';

import CSS from './Progress.scss';

export default function Progress({ route }) {
  const style = { transform: `translateX(${-100 + route.progress}%)` };

  return (
    <div className={CSS.progress} style={style}>
      <progress max={100} value={route.progress} />
    </div>
  );
}

Progress.propTypes = {
  route: shape({
    progress: number,
  }).isRequired,
};
