// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".image-4AIfe{margin:0 auto;max-width:100%;width:400px;height:auto;transform:translateX(-16px)}.emailLink-1yB8B{display:inline-block;width:100%;color:#948784}.confirm-3pMIK{position:relative;align-self:center}.i18n-signup-verifyEmail-isResend{display:block;margin-bottom:1em}.premium-signup-enter .image-4AIfe,.premium-signup-enter .actions-2O77n,.premium-signup-appear .image-4AIfe,.premium-signup-appear .actions-2O77n{opacity:0;transform:translateY(10px)}.premium-signup-enter.premium-signup-enter-active .image-4AIfe,.premium-signup-enter.premium-signup-enter-active .actions-2O77n,.premium-signup-appear.premium-signup-appear-active .image-4AIfe,.premium-signup-appear.premium-signup-appear-active .actions-2O77n{opacity:1;transform:translateY(0);transition:transform 0.15s ease-out 1.05s, opacity 0.15s ease-out 1.05s}.premium-signup-enter.premium-signup-enter-active .actions-2O77n,.premium-signup-appear.premium-signup-appear-active .actions-2O77n{transition-delay:1.2s, 1.2s}.premium-signup-leave .image-4AIfe,.premium-signup-leave .actions-2O77n{opacity:1;transform:translateY(0)}.premium-signup-leave.premium-signup-leave-active .image-4AIfe,.premium-signup-leave.premium-signup-leave-active .actions-2O77n{opacity:0;transform:translateY(-10px);transition:transform 0.15s ease-out 0.3s, opacity 0.15s ease-out 0.3s}.premium-signup-leave.premium-signup-leave-active .actions-2O77n{transition-delay:0.45s, 0.45s}\n", ""]);
// Exports
exports.locals = {
	"image": "image-4AIfe",
	"emailLink": "emailLink-1yB8B",
	"confirm": "confirm-3pMIK",
	"actions": "actions-2O77n"
};
module.exports = exports;
