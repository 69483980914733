import altConnect from 'higher-order-components/altConnect';
import { compose, withHandlers } from '@blendle/recompose';
import { isFollowingEntity, labelForLanguage } from 'selectors/entity';
import EntityActions from 'actions/EntityActions';
import EntityStore from 'stores/EntityStore';
import AuthStore from 'stores/AuthStore';
import EntityChip from 'components/EntityChip';

export function mapStateToProps({ entityState, authState }, { entityId }) {
  const entity = entityState.entities.find((itemEntity) => itemEntity.id === entityId);
  const userLanguage = authState.user.get('primary_language').substr(0, 2);

  return {
    label: labelForLanguage(userLanguage, entity),
    isChecked: isFollowingEntity(entityState.userEntities, entityId),
  };
}

mapStateToProps.stores = { EntityStore, AuthStore };

const enhance = compose(
  withHandlers({
    onClick: ({ entityId }) => () => {
      const { userEntities } = EntityStore.getState();
      const { user } = AuthStore.getState();

      if (isFollowingEntity(userEntities, entityId)) {
        EntityActions.unfollowEntity(user.id, entityId);
      } else {
        EntityActions.followEntity(user.id, entityId);
      }
    },
  }),
  altConnect(mapStateToProps),
);

export default enhance(EntityChip);
