import React from 'react';
import { compose } from '@blendle/recompose';
import withRouter from 'react-router/lib/withRouter';
import { replaceLastPath } from 'helpers/url';
import ChannelsFooter from '../components/ChannelsFooter';

// eslint-disable-next-line react/prop-types
const ChannelsFooterContainer = ({ location }) => (
  <ChannelsFooter nextRoute={replaceLastPath(location.pathname, 'publications')} />
);

export default compose(withRouter)(ChannelsFooterContainer);
