import React from 'react';
import AffiliatesStore from 'stores/AffiliatesStore';
import SignUp from 'modules/premiumSignup/components/Signup';
import { prop } from 'ramda';
import useAlt from 'hooks/useAlt';

const SignUpContainer = () => {
  const [affiliatesState] = useAlt([AffiliatesStore]);

  const { meta } = affiliatesState;
  const getIsUserEligibleForTrialMonth = prop('isUserEligibleForTrialMonth', meta);

  const dialogTitle = getIsUserEligibleForTrialMonth
    ? 'Cadeautje van Vodafone'
    : 'Blendle via Vodafone';
  const introText = `Meld je aan bij Blendle en lees het beste uit 161 kranten en tijdschriften${
    getIsUserEligibleForTrialMonth ? ' een maand gratis!' : '.'
  }`;

  return <SignUp dialogTitle={dialogTitle} introText={introText} />;
};

export default SignUpContainer;
