import React, { Component } from 'react';
import { translate } from 'instances/i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Analytics from 'instances/analytics';
import { track } from 'helpers/premiumOnboardingEvents';
import DialogHeader from 'modules/premiumSignup/components/DialogHeader';
import DialogSubheader from 'modules/premiumSignup/components/DialogSubheader';
import LinkWhenCapabilityIsLocked from './LinkWhenCapabilityIsLocked';
import CSS from './Channels.scss';

const getChannelId = channel => channel.id;

class Channels extends Component {
  static propTypes = {
    channels: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onSelectChannel: PropTypes.func.isRequired,
    selectedChannels: PropTypes.array.isRequired,
    clickPositions: PropTypes.object.isRequired,
    isOnboarding: PropTypes.bool.isRequired,
    isDeeplinkSignUp: PropTypes.bool.isRequired,
    isPreferencesFlow: PropTypes.bool.isRequired,
    didSignupOnPremiumLandingPage: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialSelection: [],
      hasSentBrowseEvent: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { channels, selectedChannels } = this.props;

    if (channels.length) {
      this.setState({
        initialSelection: selectedChannels,
      });

      const channelIds = channels.map(getChannelId);
      this.trackBrowseOnce(channelIds);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { channels } = this.props;

    if (!channels.length && nextProps.channels.length) {
      const channelIds = nextProps.channels.map(getChannelId);

      this.setState({
        initialSelection: nextProps.selectedChannels,
      });

      this.trackBrowseOnce(channelIds);
    }
  }

  trackBrowseOnce = channelIds => {
    const { hasSentBrowseEvent } = this.state;

    // Only track browse events once per mount
    if (hasSentBrowseEvent) {
      return;
    }

    track(Analytics, 'Browse Channels', { channel_uids: channelIds });
    this.setState({ hasSentBrowseEvent: true });
  };

  renderRipple(clickPosition = {}, selected, channel) {
    const style = {
      top: clickPosition.y || 0,
      left: clickPosition.x || 0,
      width: clickPosition.inkDiameter || (selected ? '100%' : 0),
      height: clickPosition.inkDiameter || (selected ? '100%' : 0),
      background: channel.get('channel_color'),
    };

    const classes = classNames(CSS.ripple, {
      [CSS.rippleIn]: selected,
    });

    return <span className={classes} style={style} />;
  }

  renderChannelList = () => {
    const {
      channels,
      onSelectChannel,
      selectedChannels,
      isOnboarding,
      clickPositions,
    } = this.props;

    return channels.map(channel => {
      const labelStyle = { color: channel.get('channel_color') };
      const selected = selectedChannels.includes(channel.id);
      const liClasses = classNames(CSS.channel, {
        [CSS.selected]: selected,
      });
      const liStyle = {};

      if (selected) {
        labelStyle.borderColor = channel.get('channel_color');
        liStyle.background = channel.get('channel_color');
      }

      return (
        <li key={channel.id} className={liClasses} style={liStyle} data-testid="channel-item">
          <LinkWhenCapabilityIsLocked isOnboarding={isOnboarding}>
            <span className={CSS.checkIcon} />
            <label
              className={`${CSS.channelName} channel-${channel.id}-hover-text`}
              htmlFor={`checkbox-${channel.id}`}
            >
              <input
                id={`checkbox-${channel.id}`}
                type="checkbox"
                checked={selected}
                className={CSS.checkbox}
                onChange={e => onSelectChannel(e, channel)}
              />
              {channel.get('full_name')}
            </label>
            {this.renderRipple(clickPositions[channel.id], selected, channel)}
          </LinkWhenCapabilityIsLocked>
        </li>
      );
    });
  };

  renderTitle() {
    const {
      isDeeplinkSignUp,
      isOnboarding,
      didSignupOnPremiumLandingPage,
      isPreferencesFlow,
    } = this.props;
    const isOnboardingRegularSignup = isOnboarding && !isDeeplinkSignUp && !isPreferencesFlow;
    const isOnboardingDeeplinkSignup = isOnboarding && isDeeplinkSignUp && !isPreferencesFlow;

    if (isOnboardingRegularSignup) {
      return translate('preferences.channels.onboarding_title');
    }

    if (isOnboardingDeeplinkSignup && didSignupOnPremiumLandingPage) {
      return translate('preferences.channels.title');
    }

    if (isOnboardingDeeplinkSignup && !didSignupOnPremiumLandingPage) {
      return translate('preferences.channels.deeplink_title');
    }

    return translate('preferences.channels.title');
  }

  renderSubtitle() {
    const {
      isDeeplinkSignUp,
      isOnboarding,
      didSignupOnPremiumLandingPage,
      isPreferencesFlow,
    } = this.props;
    const { initialSelection } = this.state;

    const isOnboardingRegularSignup = isOnboarding && !isDeeplinkSignUp && !isPreferencesFlow;
    const isOnboardingDeeplinkSignup = isOnboarding && isDeeplinkSignUp && !isPreferencesFlow;

    if (isOnboardingRegularSignup) {
      return translate('preferences.channels.deeplink_subtitle');
    }

    if (isOnboardingDeeplinkSignup && didSignupOnPremiumLandingPage) {
      return 'Dan maken we Blendle voor je op maat en kun je optimaal gebruik maken van je gratis maand.';
    }

    if (isOnboardingDeeplinkSignup && !didSignupOnPremiumLandingPage) {
      return translate('preferences.channels.deeplink_subtitle');
    }

    if (!isOnboarding || isPreferencesFlow) {
      return null;
    }

    const following = Math.max(initialSelection.length, 0);

    if (following === 0) {
      return translate('preferences.channels.no_following_subtitle');
    }

    return translate('preferences.channels.subtitle');
  }

  render() {
    return (
      <div data-testid="channelsContainer">
        <DialogHeader className={CSS.header}>{this.renderTitle()}</DialogHeader>
        <DialogSubheader className={CSS.subheader}>{this.renderSubtitle()}</DialogSubheader>
        <ul className={CSS.list}>{this.renderChannelList()}</ul>
      </div>
    );
  }
}

export default Channels;
