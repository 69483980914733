// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes slide-in-2Spxb{from{left:-100%}to{left:0}}.progress-fDrjj{position:absolute;z-index:100;top:0;left:0;width:100%;height:6px;animation:slide-in-2Spxb 0.8s;transition:transform 0.4s;overflow:hidden;background:#ff6255}.progress-fDrjj progress{display:none}\n", ""]);
// Exports
exports.locals = {
	"progress": "progress-fDrjj",
	"slide-in": "slide-in-2Spxb"
};
module.exports = exports;
