// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bodyContent-XMDvs{width:100%;max-width:700px;margin:0 auto 40px;user-select:none}.subheader-2dGR3{width:750px}.premium-signup-enter .bodyContent-XMDvs,.premium-signup-appear .bodyContent-XMDvs{opacity:0;transform:translateY(10px)}.premium-signup-enter.premium-signup-enter-active .bodyContent-XMDvs,.premium-signup-appear.premium-signup-appear-active .bodyContent-XMDvs{opacity:1;transform:translateY(0);transition:transform 0.15s ease-out 1.05s, opacity 0.15s ease-out 1.05s}.premium-signup-leave .bodyContent-XMDvs{opacity:1;transform:translateY(0)}.premium-signup-leave.premium-signup-leave-active .bodyContent-XMDvs{opacity:0;transform:translateY(-10px);transition:transform 0.15s ease-out 0.3s, opacity 0.15s ease-out 0.3s}\n", ""]);
// Exports
exports.locals = {
	"bodyContent": "bodyContent-XMDvs",
	"subheader": "subheader-2dGR3"
};
module.exports = exports;
