// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".landingPage-19IGj{font-family:\"GT-Walsheim\",sans-serif;width:100%;min-height:100%;display:flex;flex-direction:column;background:white}.landingPage-19IGj>*{overflow-x:hidden}.sectionFullscreen-4NGtJ{min-height:100vh}@media screen and (max-width: 600px){.sectionFullscreen-4NGtJ{min-height:auto}}.sectionInner-3XYEk{position:relative;width:100%;max-width:1100px;padding:0 50px;margin:0 auto}@media screen and (max-width: 600px){.sectionInner-3XYEk{padding:15px}}.cta-3VEYt{padding:0 25px;display:inline-block;box-shadow:0 2px 6px rgba(0,0,0,0.2);font-family:\"GT-Walsheim\",sans-serif;font-weight:bold;font-size:1.28571em}@keyframes slideUp-2DuJ7{from{transform:translateY(10px);opacity:0}to{transition:none;opacity:1}}.slideUp-2DuJ7{animation:slideUp-2DuJ7 0.3s 0.1s ease-out forwards}\n", ""]);
// Exports
exports.locals = {
	"landingPage": "landingPage-19IGj",
	"sectionFullscreen": "sectionFullscreen-4NGtJ",
	"sectionInner": "sectionInner-3XYEk",
	"cta": "cta-3VEYt",
	"slideUp": "slideUp-2DuJ7"
};
module.exports = exports;
