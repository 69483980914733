// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header-cc2Qf{font-family:'GT-Walsheim', sans-serif;margin:30px 0 16px;font-size:32px}@media (min-width: 600px){.header-cc2Qf{margin:70px 0 16px;font-size:42px}}\n", ""]);
// Exports
exports.locals = {
	"header": "header-cc2Qf"
};
module.exports = exports;
