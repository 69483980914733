import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'instances/i18n';
import LogoLink from 'components/navigation/LogoLink';
import Link from 'components/Link';
import { Button, NavigationBar, NavigationLeft, NavigationRight } from '@blendle/lego';
import UserDropdownContainer from 'containers/navigation/UserDropdownContainer';
import CSS from './styles.scss';

class PremiumNavigation extends PureComponent {
  static propTypes = {
    mobile: PropTypes.bool.isRequired,
    authorizedUser: PropTypes.object,
    loginUrl: PropTypes.string.isRequired,
  };

  renderNavigation() {
    const { authorizedUser, loginUrl } = this.props;

    if (authorizedUser) {
      return <UserDropdownContainer />;
    }

    return (
      <div className={CSS.loginWrapper}>
        <Link href={loginUrl}>
          <Button className={CSS.loginButton} color="cash-green">
            {translate('app.buttons.login')}
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    const { mobile } = this.props;
    const sideMargin = mobile ? 14 : 20;
    const style = { marginLeft: sideMargin, marginRight: sideMargin };

    return (
      <NavigationBar>
        <NavigationLeft>
          <LogoLink width={97} height={26} style={style} />
        </NavigationLeft>
        <NavigationRight>{this.renderNavigation()}</NavigationRight>
      </NavigationBar>
    );
  }
}

export default PremiumNavigation;
