// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".links-1rouY{display:flex}.links-1rouY>*{margin:0;padding:0}.m-premiumsignup.s-not-loggedin .v-primary-navigation{padding-right:15px}.m-premiumsignup h1,.m-premiumsignup h2,.m-premiumsignup h3,.m-premiumsignup h4,.m-premiumsignup h5,.m-premiumsignup h6{font-family:\"GT-Walsheim\",sans-serif}.m-premiumsignup .a-main{background-color:#fff;padding:0}.m-premiumsignup .a-header{position:relative;max-width:100%;min-width:0}.m-premiumsignup .a-footer{display:none}.m-premiumsignup .a-content{padding:0;height:auto;min-height:calc(100vh - 50px);min-width:100%;position:static}@media screen and (min-width: 600px){.m-premiumsignup .a-content{min-height:calc(100vh - 62px)}}.m-premiumsignup .a-content h2{font-size:2.28571em;margin-bottom:1em}@media screen and (max-width: 600px){.m-premiumsignup .a-content h2{font-size:1.71429em}}.m-premiumsignup .a-content dialog h2{font-size:24px}.m-premiumsignup .a-content dialog p{font-size:1em}.m-premiumsignup .a-content .login-email-message{font-size:0.857em;text-align:left}@media screen and (max-width: 600px){.m-premiumsignup .a-content .btn{width:100%}}.m-premiumsignup .v-primary-navigation-items a{font-family:\"GT-Walsheim\",sans-serif}@media screen and (max-width: 1025px){.m-premiumsignup .v-primary-navigation-items a{font-size:13px;margin-right:15px}}@media screen and (max-width: 768px){.m-premiumsignup .v-primary-navigation-items a{margin-right:10px}}@media screen and (max-width: 724px){.m-premiumsignup .v-primary-navigation-items a{display:none !important}}.loginWrapper-hkHXH{display:flex;align-items:center;float:right;height:100%}.loginButton-28g08{height:32px;padding-top:0;padding-bottom:0;position:relative;top:1px;margin-right:15px}@media screen and (min-width: 768px){.loginButton-28g08{height:42px;top:auto;margin-right:20px}}\n", ""]);
// Exports
exports.locals = {
	"links": "links-1rouY",
	"loginWrapper": "loginWrapper-hkHXH",
	"loginButton": "loginButton-28g08"
};
module.exports = exports;
