import { compose } from '@blendle/recompose';
import withRouter from 'react-router/lib/withRouter';
import altConnect from 'higher-order-components/altConnect';
import AffiliatesStore from 'stores/AffiliatesStore';
import Auth from 'controllers/auth';
import PremiumLanding from 'modules/premiumSignup/components/PremiumLanding';
import withAuthListener from '../higher-order-components/withAuthListener';

function mapStateToProps(
  { affiliatesState },
  { header, body, footer, overlay, dialog, router, route, topBanner },
) {
  return {
    user: Auth.getUser(),
    affiliate: affiliatesState.affiliate,
    header,
    body,
    footer,
    overlay,
    dialog,
    router,
    route,
    topBanner,
  };
}

mapStateToProps.stores = { AffiliatesStore };

const enhance = compose(withRouter, withAuthListener, altConnect(mapStateToProps));

export default enhance(PremiumLanding);
