// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subheader-3oqGJ{font-family:\"Proxima Nova\",Helvetica,Arial,sans-serif;max-width:80%;font-size:18px;color:#948784;margin:0 auto 20px;word-wrap:break-word}@media (min-width: 600px){.subheader-3oqGJ{margin:0 auto 50px;font-size:24px}}.subheader-3oqGJ a{color:#ff1060}\n", ""]);
// Exports
exports.locals = {
	"subheader": "subheader-3oqGJ"
};
module.exports = exports;
