// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".openMail-3DrFU{position:relative;align-self:center}.envelope-3GQx0{vertical-align:middle;fill:#fff;margin:-2px 8px 0 0}\n", ""]);
// Exports
exports.locals = {
	"openMail": "openMail-3DrFU",
	"envelope": "envelope-3GQx0"
};
module.exports = exports;
