import { ENTITY_STATE } from 'app-constants';

export function isFollowingEntity(userEntities, entityId) {
  const userEntity = userEntities.get(entityId);

  if (!userEntity) {
    return false;
  }

  return userEntity.state === ENTITY_STATE.FOLLOW;
}

export function filterProminent(entities) {
  // TODO: make sure it's prominent in the user's country
  return entities.filter(entity => entity.prominent_in.length > 0);
}

export function labelForLanguage(language, entity) {
  const translatedLabel = entity.label[language];

  return translatedLabel || entity.label.default;
}
