import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LandingHeader from './LandingHeader';
import withDialogs from '../../higher-order-components/withDialogs';
import CSS from './style.scss';

class PremiumLandingPage extends PureComponent {
  static propTypes = {
    dialog: PropTypes.node,
  };

  renderDialog() {
    return this.props.dialog || null;
  }

  render() {
    return (
      <div className={CSS.landingPage} data-testid="premium-landing">
        <div className={CSS.content}>
          <LandingHeader className={CSS.sectionFullscreen} />
        </div>
        {this.renderDialog()}
      </div>
    );
  }
}

export default withDialogs(PremiumLandingPage);
