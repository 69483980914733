// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".authDialog-2qvcN{height:100%;margin-bottom:0 !important}.authDialog-2qvcN .fb-promise{display:inline;line-height:14px}.authDialog-2qvcN .lnk-not-you{color:#ff6255;margin:0;padding-left:0.2em;display:inline-block;line-height:inherit}.authDialog-2qvcN .lnk-not-you:hover{color:#ff6255}.authDialog-2qvcN h2{margin-top:10px}.stepsContainer-2br9R{min-height:420px;margin-bottom:10px}.step-1uHCj{display:flex;flex-direction:column}.premium-signup-enter.authDialog-2qvcN,.premium-signup-appear.authDialog-2qvcN{opacity:0;transform:scale(0.95)}.premium-signup-appear.premium-signup-appear-active.authDialog-2qvcN{opacity:1;transform:scale(1);transition:transform 0.15s ease-out 0.2s, opacity 0.15s ease-out 0.2s}.premium-signup-enter.premium-signup-enter-active.authDialog-2qvcN{opacity:1;transform:scale(1);transition:transform 0.15s ease-out 0.65s, opacity 0.15s ease-out 0.65s}.premium-signup-leave.authDialog-2qvcN{opacity:1;transform:scale(1);max-width:330px;max-height:520px;margin:auto}.premium-signup-leave.premium-signup-leave-active.authDialog-2qvcN{opacity:0;transform:scale(0.95);transition:transform 0.15s ease-out, opacity 0.15s ease-out}\n", ""]);
// Exports
exports.locals = {
	"authDialog": "authDialog-2qvcN",
	"stepsContainer": "stepsContainer-2br9R",
	"step": "step-1uHCj"
};
module.exports = exports;
